
$blue-edr: #0045B2;
$blue-lighter-edr: #e0f1ff;
$cian-edr: #19C5C7;
$cian-light-edr: #7DFEFF;
$orange-edr: rgba(236, 160, 17, 1);
$orange-mid-light-edr: #f7bb37;
$orange-light-edr: rgba(249, 204, 104, 1);
$gray-light-edr: #d5d5d5;

$bot-bar-border-radius: 5px;