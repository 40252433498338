$scrollbar-width: 5px;
$chat-thread-bgd-color: #FFF;
$chat-thread-msg-arrow-size: 10px;
$chat-thread-avatar-size: 25px;
$chat-thread-offset: #{$chat-thread-avatar-size + 20px};
$default-shadow-color: rgb(0, 0, 0);
// Mixins
// --------------------------------------
@mixin fancy-scrollbar() {
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  &::-webkit-scrollbar-track {
    border-radius: $scrollbar-width;
    background-color: rgba(25, 147, 147, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $scrollbar-width;
    background-color: $chat-thread-bgd-color;
  }
}

@mixin scrolling-shadows($background-color: transparent, $shadow-intensity: 0.5, $shadow-color: $default-shadow-color, $cover-size: 40px, $shadow-size: 14px) {
  // Shadow covers
  background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)), radial-gradient(50% 100%, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)) 0 100%;
  background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0));
  // also add button shadow:
  //radial-gradient(farthest-side at 50% 100%, rgba($shadow-color,$shadow-intensity), rgba($shadow-color,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: $background-color;
  background-size: 100% $cover-size, 100% $cover-size, 100% $shadow-size, 100% $shadow-size;
  // Opera doesn't support this in the shorthand
  background-attachment: local, local, scroll, scroll;
}

.icon-chat {
  font-size: 38px;
  color: #00307F !important;
  cursor: pointer;
}
.floating-chat {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  position: fixed;
  bottom: 30px; right: 30px;
  width: 78px; height: 78px;

  transform: translateY(70px);


  border-radius: 50%;

  opacity: 0;




  &.enter:hover {
    opacity: 1;
  }

  &.enter {
    background-color: #7EFEFF;
    transform: translateY(0);
    opacity: 1;
  }

  &.expand {
    width: 350px;
    max-height: 230px;
    height: 230px;
    border-radius: 5px;
    right: 0px;
    cursor: auto;
    opacity: 1;
    background-color: transparent !important;
  }

  :focus {
    outline: 0;
    box-shadow: 0 0 3pt 2pt rgba(#0EC879, 0.3);
  }

  button {
    background: transparent;
    border: 0;
    color: white;
    text-transform: uppercase;
    border-radius: 3px;
    cursor: pointer;
  }
  .chat {
    display: flex;
    flex-direction: column;
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    transition: all 250ms ease-out;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.enter {
      opacity: 1;
      border-radius: 0;
      margin: 10px;
      width: auto;
      height: auto;
    }

    .messages {
      padding: 10px;
      margin: 0;
      list-style: none;
      flex-grow: 1;
      border-radius: 4px;
      background: transparent;

      @include fancy-scrollbar;

      li {
        position: relative;
        clear: both;
        display: inline-block;
        padding: 14px;
        margin: 0 0 20px 0;
        font-family: 'Work Sans', sans-serif;
        font-size: 16px;
        color: #00307F !important;
        border-radius: 10px;
        background-color: $chat-thread-bgd-color;
        word-wrap: break-word;
        max-width: 81%;

        &:after {
          position: absolute;
          bottom: $chat-thread-msg-arrow-size;
          content: '';
          width: 0;
          height: 0;
          border-bottom: $chat-thread-msg-arrow-size solid $chat-thread-bgd-color;
        }
      }
      li.other {
        animation: show-chat-odd 0.15s 1 ease-in;
        -moz-animation: show-chat-odd 0.15s 1 ease-in;
        -webkit-animation: show-chat-odd 0.15s 1 ease-in;
        float: right;
        margin-right: $chat-thread-offset;
        color: #0AD5C1;
      }
      li.other:before {
        right: -$chat-thread-offset;
        // Placeholder avatar 1
        background-image: url(https://github.com/Thatkookooguy.png);
      }
      li.other:after {
        border-right: $chat-thread-msg-arrow-size solid transparent;
        right: -$chat-thread-msg-arrow-size;
      }
      li.self {
        animation: show-chat-even 0.15s 1 ease-in;
        -moz-animation: show-chat-even 0.15s 1 ease-in;
        -webkit-animation: show-chat-even 0.15s 1 ease-in;
        float: left;
        margin-left: $chat-thread-offset;
        color: #0EC879;
      }
      li.self:before {
        left: -$chat-thread-offset;
        // Placeholder avatar 2
        background-image: url(https://github.com/ortichon.png);
      }
      li.without-self {
        right: 20px;
        position: absolute;
        bottom: -30px;
        border-radius: 50%;
        width: 78px;
        height: 78px;
        i {
          margin-top: 5px;
          margin-left: 3px;
        }
      }
      li.without-self:after {
        border-left: none !important;
        left: 0px !important;
      }
      li.self:after {
        border-left: $chat-thread-msg-arrow-size solid transparent;
        left: -$chat-thread-msg-arrow-size;
      }
    }
    .footer {
      flex-shrink: 0;
      display: flex;
      //flex-direction: row-reverse;
      padding-top: 10px;
      max-height: 90px;
      background: transparent;
      .text-box {
        border-radius: 3px;
        background: $chat-thread-bgd-color;
        min-height: 100%;
        width: 100%;
        margin-right: 5px;
        color: #0EC879;
        overflow-y: auto;
        padding: 2px 5px;
        @include fancy-scrollbar;
      }
    }
  }
}

// Animation
// --------------------------------------
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

