/**
 * Custom css/scss code
 */

@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700,800&display=swap");
@import "partial/variables";
@import "partial/mixins";
@import "components/chatbot-icon";
@import "components/bot/bot";
@import "components/player";

body {
  background-color: white;
  height: 100%;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  scroll-behavior: smooth;
}
#header-nav {
  height: 100vh;
}

#header-website-info {
  min-height: 125px !important;
}

#navbarsExampleDefault {
  .navbar-brand {
    padding-top: 12px;
    &:hover {
      color: $blue-lighter-edr !important;
    }
  }

  a.dropdown-toggle {
    &:after {
      display: none;
    }
  }
  div.dropdown-menu {
    background-color: #7efeff;
    border-radius: 5px;
  }

  .nav-item {
    .btn {
      font-size: 15px;
    }
  }
}

/*
.navbar-toggler {
  background-color: #7efeff;
}
*/

/* Set the border color */

.navbar-toggler {
  border-color: #7efeff;
}
/* Setting the stroke to green using rgb values (0, 128, 0) */

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(126, 254, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.container-cc {
  @media screen and (min-width: 1300px) {
    width: 1300px;
    max-width: 1300px;
  }
}

.bg-home {
  background-color: #4e555b;
  background-image: url("../images/img-BG-slidehome.jpg");
  background-size: cover;
}

.btn {
  border-radius: 27px;
  padding: 10px 20px 10px 20px;
  font-size: 16px;

  &-ed-cian {
    color: #00307f !important;
    background-color: #7efeff;
    border-color: #7efeff;
    &:hover,
    &:focus,
    &:active {
      color: #00317f;
      background-color: #74e3e3;
      border-color: #7efeff;
    }
    .badge {
      color: #7efeff;
      background-color: #00317f;
    }
  }

  &-ed-blue {
    color: #ffffff !important;
    background-color: $blue-edr;
    border-color: $blue-edr;
    border-radius: 30px;
    padding-top: 13px;
    padding-bottom: 13px;
    &:hover,
    &:focus,
    &:active {
      color: #ffffff;
      background-color: #003588;
      border-color: #003588;
    }
  }

  &-ed-white {
    color: $blue-edr !important;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &-ed-blue-light {
    color: $blue-edr !important;
    background-color: #f3f9fd;
    border-color: #ffffff;
    border-radius: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &-ed-transp {
    color: #ffffff;
    background-color: transparent;
    border-color: transparent;
    &:hover,
    &:focus,
    &:active {
      color: #74e3e3;
      border-color: transparent;
    }
  }

  &-ed-big {
    font-size: 20px;
    padding: 12px 30px;
  }

  &.transcription-btn {
    background: white;
    color: $blue-edr;
    text-transform: uppercase;
    padding: 5px 20px;
    font-size: 0.8em;
    font-weight: 600;
    margin-right: 5px;
    &:enabled:hover {
      background: $blue-edr;
      color: white;
    }
    &:disabled {
      color: $gray-light-edr;
      &:hover {
        cursor: default;
      }
    }
    i {
      padding-right: 5px;
    }
  }
}

.btn-ed-white:hover {
  color: $blue-edr !important;
  background-color: #d6efff;
}

.btn-ed-blue-light:hover {
  color: $blue-edr !important;
  background-color: #d6efff;
}

.text-header-nav {
  margin-top: 165px;
  line-height: 60px;
  color: #ffffff;
  font-size: 50px;
  font-weight: 300;
  &-bold {
    font-weight: 700;
  }
}
.text-header-nav-about {
  margin-top: 40px;
}

.btn-more {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: white;
  position: absolute;
  width: 100%;
  bottom: 25px;
}

#whats {
  height: 400px;
}
@media screen and (max-width: 39.9375em) {
  #whats {
    height: 550px;
  }
}

.title {
  color: $blue-edr;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.sub-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.text-box-medium {
  font-size: 16px;
  color: $blue-edr;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

#platform {
  height: 400px;
}
@media screen and (max-width: 39.9375em) {
  #platform {
    height: 550px;
  }
}

#testimonials {
  min-height: 600px;
  background-color: #f3f9fd;
  padding-bottom: 110px;
  .carousel-inner {
    background-color: white;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 35px 7px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 0px 35px 7px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 0px 35px 7px rgba(0, 0, 0, 0.14);
  }
  .carousel-control-next,
  .carousel-control-prev {
    margin: -110px;
  }
  .carousel-indicators {
    bottom: -65px;
  }
  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    background-color: $blue-edr;
  }
}

/* ABOUT US*/
#header-about {
  height: 550px;
  background-color: #4e555b;
  background-image: url("../images/footer-BG-.jpg");
  background-size: cover;
}
.img-autores {
  border-radius: 10px;
  width: 210px;
  height: 210px;
}
#colaboradores {
  background-color: #f3f9fd;
  padding-bottom: 110px;
  margin-top: 15px;
}
#registrase,
.cta-block {
  padding-top: 100px;
  padding-bottom: 100px;
}
.box-register-about {
  background-image: url("../images/CTA-banner-BG.jpg");
  background-size: cover;
  padding: 100px;
  border-radius: 10px;
}

/* END ABOUT US*/

/* EBO EXAM */

#header-ebo-exam {
  height: 550px;
  background-color: #4e555b;
  background-image: url("../images/header-ebo-exam.jpg");
  background-size: cover;

  p.ebo-exam {
    margin-top: 100px;
  }
}
#paraquesirve {
  padding-top: 70px;
  padding-bottom: 70px;
}
#fases {
  padding-top: 65px;
  padding-bottom: 65px;
}
.play-ebo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
}
.box-tematicas {
  background-color: #f3f9fd;
  border-radius: 10px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
}
/* END EBO EXAM */

/* SIGN IN*/
#header-signin {
  background-color: #4e555b;
  background-image: url("../images/header-login.jpg");
  background-size: cover;

  .text-header-nav {
    margin-top: 35px;
    margin-bottom: 0;
  }

  .title {
    color: $cian-edr;
    margin-bottom: 0;
  }
}
/* END SIGN IN*/

/* WELCOME */
#submenu-bar a:hover {
  text-decoration: none;
  color: $cian-edr !important;
}
#header-welcome {
  background-color: #4e555b;
  background-image: url("../images/header-ebo-exam.jpg");
  background-size: cover;
  padding-bottom: 10px;
}
#welcome {
  padding-top: 65px;
  padding-bottom: 65px;
}
.box-welcome {
  background-color: #f3f9fd;
  border-radius: 10px;
  line-height: 24px;
  padding: 60px 100px;
  p {
    margin: 0;
  }
}
.sub-menu-side {
  width: 100%;
}

.tree,
.tree ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    &.active {
      font-weight: 600;
    }
  }
}
.tree ul {
  margin-left: 1em;
  position: relative;
}
.tree ul ul {
  margin-left: 0.5em;
}

.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 1.5em;
  color: #369;
  font-weight: 400;
  position: relative;
}
.tree li {
  padding-left: 0 !important;
}
.indicator {
  margin-right: 5px;
}
.tree li a {
  text-decoration: none;
  color: $blue-edr;
}
.tree li button,
.tree li button:active,
.tree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  outline: 0;
}

.active {
  font-weight: 600;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
  background-color: $blue-edr;
  color: white !important;
}

.true-turquoise {
  border-radius: 30px;
  color: #ffffff !important;
  background-color: #1ac6c7;
  border-color: #1ac6c7;
  padding: 5px 20px 5px 20px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.wrong-danger {
  border-radius: 30px;
  color: #ffffff !important;
  background-color: #e66754;
  border-color: #e66754;
  padding: 5px 20px 5px 20px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.true-false-white {
  border-radius: 30px;
  color: $blue-edr !important;
  background-color: #ffffff;
  border-color: #1ac6c7;
  padding: 5px 20px 5px 20px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.false-danger {
  color: #ffffff !important;
  background-color: #e66754;
  border-color: #e66754;
}

p {
  &.final-exam {
    line-height: 15px;

    &:hover {
      text-decoration: underline;
    }
  }
}
/* END WELCOME */

/* FOOTER */
footer {
  background-color: #4e555b;
  background-image: url("../images/footer-BG-.jpg");
  background-size: cover;

  .text-footer {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  p {
    &.sponsors {
      font-size: 12px;
    }
  }
}

/* UTILS */

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

input {
  height: 57px !important;
}
select {
  padding: 15px !important;
  height: 57px !important;
}
textarea {
  padding: 10px !important;
}
.form-check input {
  height: auto !important;
}
.divider-vertical {
  width: 1px;
  border-right: 2px solid #54d5d6;
  height: 50px;
  margin-left: auto;
  margin-right: auto;

  &-small {
    height: 25px;
  }

  &-colorless {
    border-color: inherit;
  }
}
.divider-horizontal {
  width: 100%;
  border-bottom: 1px solid #54d5d6;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
}
.divider-horizontal-titulo {
  width: 60px;
  border-bottom: 2px solid #54d5d6;
  height: 2px;
}

.text-blue-edr {
  color: $blue-edr !important;
}
.text-cian-edr {
  color: $cian-edr !important;
}
.text-cian-light-edr {
  color: $cian-light-edr !important;
}
.text-orange-edr {
  color: $orange-edr !important;
}
.text-orange-mid-light-edr {
  color: $orange-edr !important;
}
.text-orange-light-edr {
  color: $orange-light-edr !important;
}
.size-12 {
  font-size: 12px !important;
}
.size-14 {
  font-size: 14px !important;
}
.size-16 {
  font-size: 16px !important;
}
.size-20 {
  font-size: 20px !important;
}
.size-25 {
  font-size: 25px !important;
}
.bg-blue-dark {
  background-color: #00317f !important;
}
.bg-blue-light {
  background-color: #f3f9fd !important;
}
.radius-10 {
  border-radius: 10px !important;
}
.radius-20 {
  border-radius: 20px !important;
}

.border-1 {
  border: 1px solid $gray-light-edr;
}
.border-1-blue {
  border: 1px solid $blue-edr;
}

/* Questions */
ul#questions {
  li {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.question-bg {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 10px;
  margin: 20px 0;

  p {
    margin-bottom: 0px;
  }
}
span.complete {
  font-size: 14px;
  color: #3bc6c7;
}
span.aligned {
  position: relative;
  top: 3px;
}
span.resume {
  font-size: 20px !important;
}
span.pending {
  font-size: 14px;
  color: $blue-edr;
  opacity: 0.5;

  p {
    margin-bottom: 0px;
  }
}
.premise-true {
  color: #1ac6c7;
}
.premise-false {
  color: #e66754;
}

/** Select2 set height appearance  Hint taken from https://stackoverflow.com/a/41830955 */
.select2-selection__rendered {
  line-height: 51px !important;
}
.select2-container .select2-selection--single {
  height: 55px !important;
}
.select2-selection__arrow {
  height: 54px !important;
}

/* Pictos */
.picto {
  display: inline-block;
  height: 21px;
  width: 18px;
  vertical-align: sub;
  &-hexagon-orange {
    background-image: url("../images/TEMP/picto-hexagon-orange.png");
  }
}

.grecaptcha-badge {
  /*transform:scale(0.77) !important;*/
  bottom: 120px !important;
}
/* RESPONSIVE */
@media (max-width: 766px) {
  .text-header-nav {
    margin-top: 30px;
    line-height: 35px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 300;
  }
  .sub-title {
    font-size: 16px;
    line-height: 20px;
  }
  #testimonials .carousel-control-next,
  #testimonials .carousel-control-prev {
    margin: -10px;
  }
  .box-register-about {
    padding: 45px;
  }

  #welcome {
    padding-left: 10px;
    padding-right: 10px;
    .box-welcome {
      background-color: #f3f9fd;
      border-radius: 10px;
      line-height: 24px;
      padding: 20px 10px;
      .btn {
        width: 100%;
        margin-top: 15px;
      }
      .vertical-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 800px) and (min-width: 766px) {
  #testimonials .carousel-control-next,
  #testimonials .carousel-control-prev {
    margin: -65px;
  }
}

@media screen and (max-width: 1024px) {
  .ebod-logo {
    max-width: 170px !important;
  }
}
