
.bot {
  &.welcome {

    .modal {
      &-content {
        background: none;
        border: none;
      }

      &-header {
        border: none;

        .close {
          background: #f7bb37;
          border-radius: 70px;
          opacity: 1;
          width: 40px;
          height: 40px;
          padding: 0;
          margin-bottom: 0px;
          color: white;
          text-shadow: none;
          font-size: 40px;
          font-weight: 100;

          &:focus {
            outline: none;
          }
        }
      }

      &-title {
        text-align: center;

        img {
          margin-top: -232px;
        }
      }

      &-body {
        padding-top: 140px;
        border-top-right-radius: calc(.8rem - 1px);
        border-top-left-radius: calc(.8rem - 1px);
        background: white;
        font-size: 1.2em;

        .divider-vertical {
          border-right-width: 1px;
          height: 30px;
        }
      }

      &-footer {
        border: none;
        background: white;
        border-bottom-right-radius: calc(.8rem - 1px);
        border-bottom-left-radius: calc(.8rem - 1px);

        .btn-start {
          margin: 0 auto;
        }

        .close-link {
          width: 100%;
        }
      }
    }


    @media screen and (min-width: 576px) {
      .modal {
        &-dialog {
          max-width: 700px;
        }

        &-body {
          padding-left: 120px;
          padding-right: 120px;
        }

        &-footer {
          padding-bottom: 100px;

          &:after {
            content: '';
            width: 200px;
            border-bottom: solid 10px $orange-light-edr;
            position: absolute;
            left: 50%;
            bottom: 20px;
            z-index: 1;
            border-radius: 5px;
            margin-left: -100px;
          }
        }
      }
    }
    @media screen and (max-width: 766px) {
      .modal {
        &-title {
          img {
            width: 80px;
            margin-top: -90px;
          }
        }

        &-body {
          padding-top: 50px;

          .divider-vertical {
            display: none;
          }
        }
        &-footer {
          padding-bottom: 40px;
        }
      }
    }
  }
}