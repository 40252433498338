#tt-tooltip {
  display: none;
  width: 95%;
  max-width: 550px;
  background-color: $orange-edr;
  border-radius: 10px;
  padding: 35px 40px;
  color: white;
  @include box-shadow(0, 20px, 38px, -4px, rgba(0,0,0,0.2));
  z-index: 1;

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }

  &[x-placement^="top"] {
    margin-botton: 15px;
    .arrow {
      border-width: 22px 15px 0 15px;
      border-color: $orange-edr transparent transparent transparent;
      bottom: -20px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 15px;
    .arrow {
      border-width: 0 15px 22px 15px;
      border-color: transparent transparent $orange-edr transparent;
      top: -20px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 15px;
    .arrow {
      border-width: 15px 22px 15px 0;
      border-color: transparent $orange-edr transparent transparent;
      left: -20px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 15px;
    .arrow {
      border-width: 15px 0 15px 22px;
      border-color: transparent transparent transparent $orange-edr;
      right: -20px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  .container {
    * {
      text-align: left;
    }

    .steps {
      line-height: 2.4em;
    }

    .divider-vertical {
      border-width: 1px;
      margin-left: 0;
    }

    .content {
      font-size: 1.2em;
      font-weight: 100;
    }

    .next {
      width: 70%;
    }

    .finish {
      display: block;
      color: white;
      font-weight: 100;
    }
  }

  /* RESPONSIVE */
  @media (max-width: 766px){
    padding: 10px 5px 20px;
    .container {
      .title {
        font-size: 20px;
      }
      .content {
        font-size: 1em;
      }
    }
  }

}
