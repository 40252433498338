.player {
  .btn {
    padding: 4px 20px;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 600;

    i {
      padding-right: 5px;
      font-size: 0.8em;
    }

    &-play:disabled {
      i.play {
        display: none;
      }

      i.playing {
        display: inline !important;
      }
    }

    &-ed-white {
      &:enabled:hover {
        background: $blue-edr;
        color: white !important;
      }

      &:disabled:hover {
        background: white;
        cursor: default;
      }
    }
  }
}