//TODO: Temprary hiding floating chat to show bot
.floating-chat {
  display: none;
}

.bot-area {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;

  .bot {
    &-board {
      &-active {
        min-height: 115px;
        @include box-shadow(0, 10px, 38px, -4px, rgba(0, 0, 0, 0.2));
        background: white;

        .bot-board-gem {
          width: 110px;
          height: fit-content;
          top: -90px;
          z-index: 2;

        }

        .container {
          display: block !important;
        }
      }

      &.bot-tooltip-visible {
        .container {
          .bot-board-header:after {
            content: '';
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      padding-bottom: 20px;

      &-notis {
        background: red;
        color: white;
        display: inline;
        position: absolute;
        width: 30px;
        border-radius: 15px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        right: 5%;
        top: -100px;
        z-index: 3;
        display: none;
        &:hover {
          cursor: pointer;
        }
      }

      &-gem {
        width: 110px;
        position: absolute;
        right: 5%;
        bottom: 0;
        cursor: pointer;
          .animated {
            cursor: default !important;
          }
      }

      .container {
        display: none;
      }

      &-header {
        padding-top: 30px;

        .title {
          .suffix {
            font-size: 0.6em;
            font-weight: 400;
            vertical-align: middle;
            margin-left: 20px;
          }
        }

        .bot-close {
          .icon {
            padding-right: 10px;
            font-size: 0.9em;
          }
        }
      }

      &-body {
        padding-top: 20px;
        padding-bottom: 30px;

        &-messages {
          font-size: 1.1em;
        }

        &-proposals {
          margin: 30px 15px 0;
          flex: auto;

          .box-welcome {
            padding: 40px 100px;
            margin-bottom: 1rem;
          }
        }

        &-timer {
          display: none;
        }
      }

      &-footer {

      }

      .progress {
        height: 10px;
        background: $blue-lighter-edr;
        border-radius: $bot-bar-border-radius;

        &-bar {
          background: $orange-mid-light-edr;
          border-radius: $bot-bar-border-radius;
        }
      }

      &.timerLoaded {
        #notifications-container {
          display: none;
        }
        .bot-board-body-timer {
          display: block;
        }
      }
    }
  }

  /* RESPONSIVE */
  @media (max-width: 766px) {

    .btn-ed-big {
      width: 100%;
      font-size: 1em;
      text-align: center;
    }
    .bot {
      &-board {
        &-active {
          max-height: 70vh;
          overflow: scroll;

/*          .bot-board-gem {
            width: 60px;
            top: -60px;
          }*/

          &.bot-tooltip-visible {
            top: -10px;
            position: absolute;
            overflow: visible;
          }
        }

        &-header {
          .bot-close {
            position: fixed;
            background: white;
            z-index: 1;
            height: 35px;
            margin-top: -30px;
            text-align: left !important;
            padding: 5px 15px !important;
          }
        }

        &-body {
          padding-bottom: 20px;

          &-messages {
            padding-bottom: 20px;
          }

          &-proposals {
            margin-top: 0;

            .box-welcome {
              padding: 20px 10px;
              margin: 10px 0;

              .title {
                font-size: 1.2em;
                padding: 0;
              }

              .text-blue-edr {
                padding: 0;
              }

              .btn-ed-blue {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
