#bot-tooltip-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

#bot-tooltip {
  display: none;
  width: 95%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 35px 40px 60px;
  color: white;
  @include box-shadow(0, 20px, 38px, -4px, rgba(0, 0, 0, 0.2));
  z-index: 1;

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }

  &[x-placement^="top"] {
    margin-botton: 15px;

    .arrow {
      border-width: 10px 20px 0 20px;
      border-color: white transparent transparent transparent;
      bottom: -10px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .container {

    .content {
      font-size: 1.2em;
      font-weight: 400;

      .title {
        text-align: center;
        text-transform: uppercase;
        font-size: 17px;
        padding-bottom: 20px;
        color: $orange-light-edr;
      }

      .btn {
        font-size: 1em;
        width: 70%;
        text-align: center;
        margin: 0 auto 15px;
      }

    }

    .tooltip-close {
      display: block;
      text-align: center;
      color: $blue-edr;
      font-size: 0.8em;
    }
  }

  &:after {
    content: '';
    width: 100px;
    border-bottom: solid 10px $orange-light-edr;
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 1;
    border-radius: 5px;
    margin-left: -50px;
  }

  /* RESPONSIVE */
  @media (min-width: 767px) {
    .container {
      * {
        text-align: left;
      }
    }
  }

  @media (max-width: 766px) {
    padding: 30px 5px 20px;
    .container {
      .title {
        font-size: 20px;
      }

      .content {
        font-size: 1em;
      }

      .tooltip-close {
        padding: 10px 0;
      }
    }

    &:after {
      display: none;
    }
  }

}
